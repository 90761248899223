import { FirebaseError } from "firebase/app";
import { navigate } from "gatsby";
import React from "react";
import { toast } from "react-toastify";
import {
  ContactUsContext,
  ContactUsActionType,
} from "../../core/contexts/firebase/contact-us";
import Spinner from "../shared/Spinner";

const ContactUsForm = () => {
  const [state, dispatch] = React.useContext(ContactUsContext);

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isFormValid = e.currentTarget.checkValidity();
    if (isFormValid) {
      try {
        onIsSubmittingChange(true);
        // Add logic to submit form
        await state.submit();
        onIsSubmittedChange(true);
        onIsSubmittingChange(false);
        navigate("/contact-us/success");
      } catch (error) {
        let message = "Something went wrong, please try again later.";
        if (error instanceof FirebaseError) {
          if (error.code === "permission-denied") {
            message =
              "Missin or insufficient permissions to perform this operation. Please contact support.";
          }
        }
        onIsSubmittedChange(false);
        onIsSubmittingChange(false);
        toast.error(message);
      }
    }
  };

  const onIsSubmittingChange = (isSubmitting: boolean) => {
    dispatch({
      type: ContactUsActionType.SET_IS_SUBMITTING,
      payload: isSubmitting,
    });
  };

  const onIsSubmittedChange = (isSubmitted: boolean) => {
    dispatch({
      type: ContactUsActionType.SET_IS_SUBMITTED,
      payload: isSubmitted,
    });
  };

  const onFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (name) {
      dispatch({
        type: ContactUsActionType.CHANGE_FIELD,
        payload: { name, value },
      });
    }
  };

  return (
    <form
      onSubmit={submitForm}
      className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
    >
      <div>
        <label
          htmlFor="first-name"
          className="block text-sm font-medium text-gray-900"
        >
          First name
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="firstName"
            id="first-name"
            autoComplete="given-name"
            required
            value={state.data.firstName}
            onChange={onFieldChange}
            className="block w-full rounded-md border-gray-300 bg-slate-200 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="last-name"
          className="block text-sm font-medium text-gray-900"
        >
          Last name
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="lastName"
            id="last-name"
            required
            autoComplete="family-name"
            value={state.data.lastName}
            onChange={onFieldChange}
            className="block w-full rounded-md bg-slate-200 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-900"
        >
          Email
        </label>
        <div className="mt-1">
          <input
            id="email"
            name="email"
            type="email"
            required
            autoComplete="email"
            value={state.data.email}
            onChange={onFieldChange}
            className="block w-full rounded-md border-gray-300 bg-slate-200 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
      </div>
      <div>
        <div className="flex justify-between">
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-gray-900"
          >
            Phone
          </label>
          <span id="phone-optional" className="text-sm text-gray-500">
            Optional
          </span>
        </div>
        <div className="mt-1">
          <input
            type="text"
            name="phone"
            id="phone"
            autoComplete="tel"
            value={state.data.phone}
            onChange={onFieldChange}
            className="block w-full rounded-md border-gray-300 bg-slate-200 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            aria-describedby="phone-optional"
          />
        </div>
      </div>
      <div className="sm:col-span-2">
        <label
          htmlFor="subject"
          className="block text-sm font-medium text-gray-900"
        >
          Subject
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="subject"
            id="subject"
            required
            value={state.data.subject}
            onChange={onFieldChange}
            className="block w-full rounded-md border-gray-300 bg-slate-200 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
      </div>
      <div className="sm:col-span-2">
        <div className="flex justify-between">
          <label
            htmlFor="message"
            className="block text-sm font-medium text-gray-900"
          >
            Message
          </label>
          <span id="message-max" className="text-sm text-gray-500">
            Max. 500 characters
          </span>
        </div>
        <div className="mt-1">
          <textarea
            id="message"
            name="message"
            rows={4}
            required
            value={state.data.message}
            onChange={onFieldChange}
            className="block w-full rounded-md border-gray-300 bg-slate-200 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            aria-describedby="message-max"
            defaultValue={""}
          />
        </div>
      </div>
      <div className="relative sm:col-span-2 sm:flex sm:justify-end">
        <button
          type="submit"
          disabled={state.isSubmitting}
          className="relative mt-2 inline-flex w-full items-center justify-center gap-2 rounded-md border border-transparent bg-indigo-600 py-3 pl-6 pr-12 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-gray-500 sm:w-auto"
        >
          Send a message
        </button>
        {state.isSubmitting && (
          <div className="absolute right-3 top-2 bottom-0">
            <div className="flex h-full items-center">
              <Spinner textColor="text-white" sizeClass="h-4 w-4" />
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default ContactUsForm;
