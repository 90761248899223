import * as React from "react";
import { graphql, HeadFC, useStaticQuery } from "gatsby";
import { CustomHead } from "../components/shell/CustomHead";
import Shell from "../components/shell/Shell";
import ImageHero from "../components/shared/ImageHero";
import {
  PhoneIcon,
  EnvelopeIcon,
  DocumentTextIcon,
} from "@heroicons/react/20/solid";
import Socials from "../components/shared/Socials";
import WhatsAppIcon from "../components/shared/icons/WhatsAppIcon";
import OfficeOne from "../components/shared/maps/OfficeOne";
import OfficeTwo from "../components/shared/maps/OfficeTwo";
import ContactUsForm from "../components/forms/ContactUsForm";
import { ContactUsContextProvider } from "../core/contexts/firebase/contact-us";

const pageDescription =
  "Making it easy for you to get in touch with us. You can visit us or contact us through our socials, phone, or email.";
const pagesBreadcrumb = [
  {
    name: "Contact Us",
    to: "/contact-us",
  },
];

const ContactUsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          contactEmail
          numbers {
            johannesburgOffice {
              name
              link
            }
            durbanOffice {
              name
              link
            }
            applications {
              name
              link
            }
            whatsapp {
              name
              link
            }
          }
        }
      }
    }
  `);

  const { links, contactEmail, numbers } = data.site.siteMetadata;

  return (
    <Shell>
      <ImageHero
        title="Get in touch"
        description={pageDescription}
        pages={pagesBreadcrumb}
      />
      <div className="bg-gray-100">
        <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="relative bg-white shadow-xl">
            <h2 className="sr-only">Contact us</h2>

            <div className="grid grid-cols-1 lg:grid-cols-3">
              {/* Contact information */}
              <div className="relative overflow-hidden bg-slate-200 py-10 px-6 sm:px-10 xl:p-12">
                <div
                  className="pointer-events-none absolute inset-0 sm:hidden"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 h-full w-full"
                    width={343}
                    height={388}
                    viewBox="0 0 343 388"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                      fill="url(#linear1)"
                      fillOpacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear1"
                        x1="254.553"
                        y1="107.554"
                        x2="961.66"
                        y2="814.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div
                  className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 sm:block lg:hidden"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 h-full w-full"
                    width={359}
                    height={339}
                    viewBox="0 0 359 339"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                      fill="url(#linear2)"
                      fillOpacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear2"
                        x1="192.553"
                        y1="28.553"
                        x2="899.66"
                        y2="735.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div
                  className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 lg:block"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 h-full w-full"
                    width={160}
                    height={678}
                    viewBox="0 0 160 678"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                      fill="url(#linear3)"
                      fillOpacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear3"
                        x1="192.553"
                        y1="325.553"
                        x2="899.66"
                        y2="1032.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <h3 className="text-lg font-medium text-indigo-900">
                  Contact information
                </h3>
                <p className="mt-6 max-w-3xl text-base text-gray-600">
                  Please feel free to call us at one of our offices or send us
                  an email using the form below.
                </p>
                <h3 className="mt-12 flex items-center text-lg font-medium text-indigo-900">
                  <PhoneIcon
                    className="h-6 w-6 flex-shrink-0 rounded-full bg-white p-1 text-indigo-500"
                    aria-hidden="true"
                  />
                  <span className="ml-3">Call anytime</span>
                </h3>
                <dl className="space-y-4">
                  <dt>
                    <span className="sr-only">Phone number one</span>
                  </dt>
                  <dd className="flex text-base text-gray-600">
                    <a
                      href={numbers.johannesburgOffice.link}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="inline-flex items-center py-2 text-sm font-medium leading-4 text-gray-500 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      {numbers.johannesburgOffice.name}
                    </a>
                  </dd>
                  <dt>
                    <span className="sr-only">Phone number two</span>
                  </dt>
                  <dd className="flex text-base text-gray-600">
                    <a
                      href={numbers.durbanOffice.link}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="inline-flex items-center py-2 text-sm font-medium leading-4 text-gray-500 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      {numbers.durbanOffice.name}
                    </a>
                  </dd>
                </dl>
                <h3 className="mt-12 flex items-center text-lg font-medium text-indigo-900">
                  <DocumentTextIcon
                    className="h-6 w-6 flex-shrink-0 rounded-full bg-white p-1 text-indigo-500"
                    aria-hidden="true"
                  />
                  <span className="ml-3">Applications</span>
                </h3>
                <dl className="space-y-4">
                  <dt>
                    <span className="sr-only">
                      Phone number for applications
                    </span>
                  </dt>
                  <dd className="flex text-base text-gray-600">
                    <a
                      href={numbers.applications.link}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="inline-flex items-center py-2 text-sm font-medium leading-4 text-gray-500 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      {numbers.applications.name}
                    </a>
                  </dd>
                </dl>
                <h3 className="mt-12 flex items-center text-lg font-medium text-indigo-900">
                  <WhatsAppIcon
                    className="h-6 w-6 flex-shrink-0 rounded-full bg-white p-1 text-indigo-500"
                    aria-hidden="true"
                  />
                  <span className="ml-3">WhatsApp</span>
                </h3>
                <dl className="space-y-4">
                  <dt>
                    <span className="sr-only">Phone number one</span>
                  </dt>
                  <dd className="flex text-base text-gray-600">
                    <a
                      href={numbers.whatsapp.link}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="inline-flex items-center py-2 text-sm font-medium leading-4 text-gray-500 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      {numbers.whatsapp.name}
                    </a>
                  </dd>
                </dl>
                <h3 className="mt-12 flex items-center text-lg font-medium text-indigo-900">
                  <EnvelopeIcon
                    className="h-6 w-6 flex-shrink-0 rounded-full bg-white p-1 text-indigo-500"
                    aria-hidden="true"
                  />
                  <span className="ml-3">Send an email</span>
                </h3>
                <dl className="space-y-4">
                  <dt>
                    <span className="sr-only">Phone number one</span>
                  </dt>
                  <dd className="flex text-base text-gray-600">
                    <a
                      href={`mailto:${contactEmail}`}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="inline-flex items-center py-2 text-sm font-medium leading-4 text-gray-500 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      {contactEmail}
                    </a>
                  </dd>
                </dl>
                <div className="mt-12 rounded-lg bg-white px-4 pt-4 pb-2 shadow-md">
                  <Socials />
                </div>
              </div>

              {/* Contact form */}
              <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                <h3 className="text-lg font-medium text-gray-900">
                  Leave a message
                </h3>
                <ContactUsContextProvider>
                  <ContactUsForm />
                </ContactUsContextProvider>
              </div>
            </div>
          </div>
          <div className="mt-16 pt-16 lg:grid lg:grid-cols-3 lg:gap-8">
            <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">
              Locations
            </h2>
            <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-16 lg:col-span-2 lg:mt-0">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Johannesburg
                </h3>
                <div className="mt-2 text-base text-gray-500">
                  <p>Pinewood Office Park</p>
                  <p className="mt-1">33 Riley Road, Woodmead</p>
                  <p className="mt-1">Johannesburg, 2191</p>
                </div>
              </div>
              <div className="overflow-hidden rounded-lg shadow-md">
                <OfficeOne />
              </div>
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Durban
                </h3>
                <div className="mt-2 text-base text-gray-500">
                  <p>11 Walnut Road</p>
                  <p className="mt-1">Kingsmead</p>
                  <p className="mt-1">Durban, 4001</p>
                </div>
              </div>
              <div className="overflow-hidden rounded-lg shadow-md">
                <OfficeTwo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Shell>
  );
};

export default ContactUsPage;

export const Head: HeadFC = () => (
  <CustomHead title="Contact Us" description={pageDescription} />
);
