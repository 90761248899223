import * as React from "react";

const OfficeOne = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCxYzlZfbtUBelLLB1IrUwpXxA6qEQrcjw&q=Pinewood+Office+Park,+33+Riley+Road,+Woodmead,+Johannesburg,+2191"
      className="h-full w-full"
      style={{ border: 0 }}
      loading="lazy"
    />
  );
};

export default OfficeOne;
