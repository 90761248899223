import React from "react";
import { useFirebaseService } from ".";
import { ContactUsFormModel } from "../../models/contact-us";

type State = {
  data: ContactUsFormModel;
  submit: () => Promise<void>;
  isSubmitting: boolean;
  isSubmitted: boolean;
};

export enum ContactUsActionType {
  SET_IS_SUBMITTING,
  SET_IS_SUBMITTED,
  CHANGE_FIELD,
}

type ContextAction = {
  type: ContactUsActionType;
  payload: any;
};

const initialState: State = {
  data: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subject: "General Enquiry",
    message: "",
  },
  submit: () => Promise.resolve(),
  isSubmitting: false,
  isSubmitted: false,
};

const reducer = (state: State, action: ContextAction) => {
  switch (action.type) {
    case ContactUsActionType.CHANGE_FIELD:
      const { name, value } = action.payload;
      if (name) {
        return {
          ...state,
          data: {
            ...state.data,
            [name]: value,
          },
        };
      } else {
        return state;
      }
    case ContactUsActionType.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case ContactUsActionType.SET_IS_SUBMITTED:
      return {
        ...state,
        isSubmitted: action.payload,
      };
    default:
      return state;
  }
};

export const ContactUsContext = React.createContext<
  [State, React.Dispatch<ContextAction>]
>([initialState, () => null]);

type ContactUsContextProviderProps = {
  children: React.ReactNode;
};

export const ContactUsContextProvider = ({
  children,
}: ContactUsContextProviderProps) => {
  const firebase = useFirebaseService();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  state.submit = async () => {
    if (firebase) {
      return firebase.setMailDoc(state.data);
    }
  };

  return (
    <ContactUsContext.Provider value={[state, dispatch]}>
      {children}
    </ContactUsContext.Provider>
  );
};
