import * as React from "react";

const OfficeTwo = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCxYzlZfbtUBelLLB1IrUwpXxA6qEQrcjw&q=11+Walnut+Road,+Kingsmead,+Durban,+4001"
      className="h-full w-full"
      style={{ border: 0 }}
      loading="lazy"
    />
  );
};

export default OfficeTwo;
